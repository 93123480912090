import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "@routes/main.jsx";
import Layout from "@components/page/main/Layout";
import Errorpage from "@pages/main/Errorpage";

function MainApp() {
  
  const router = createBrowserRouter([
    {
      // parent route component
      element: <Layout />,
      // your custom routing error component
      errorElement: <Errorpage />,
      // child route components
      children: routes
    }], {
	  basename: import.meta.env.VITE_APP_BASE_URL,
	})

  return (
  <>
	<RouterProvider router={router} />
  </>
  )
}

export default MainApp