import { Link } from "react-router-dom";
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

//css
import "../../assets/css/errorpage.css"

export default function Errorpage() {
	const error = useRouteError();
	let errorMessage;

	if (isRouteErrorResponse(error)) {
		// error is type `ErrorResponse`
		errorMessage = error.error?.message || error.statusText;
	} else if (error instanceof Error) {
		errorMessage = error.message;
	} else if (typeof error === 'string') {
		errorMessage = error;
	} else {
		console.error(error);
		errorMessage = 'Unknown error';
	}
	
    return (
        <section className="errorpage mx-5">
            <h1 className="title">Fehler</h1>
            <p className="not-found-message">Hier ist etwas schief gelaufen. Wo kann ich mich beschweren? 😉</p>
			<p>
				<i><strong>Fehlerdetails</strong>: { errorMessage }</i>
			</p>
            <Link to="/" className="btn btn-primary mt-3">Zurück auf die Startseite</Link>
        </section>
    )
}
