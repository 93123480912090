import React from 'react'
import ReactDOM from 'react-dom/client'
import MainApp from './MainApp'
import ProjectConstants from "./projectConstants"
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices/main";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/basic.scss'
import './assets/css/main.scss'

const store = configureStore({ reducer: rootReducer, devTools: true });

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
	  <React.Fragment>
		<MainApp />
	  </React.Fragment>
  </Provider>
)
