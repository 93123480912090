import { createSlice } from "@reduxjs/toolkit";
import { getMessages } from './thunk';
export const initialState = {
	messages: [],
	messageUsers: [],
	isMsgSuccess: false,
	error: {},
};

const MessagesSlice = createSlice({
  name: 'MessagesSlice',
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getMessages.fulfilled, (state, action) => {
		//ToDo: handle Answer
		console.log("yeah1");
		console.log(action);
		
		if(action.payload){
			console.log(action.payload);
			console.log(action.payload.data);
			const data = JSON.parse(action.payload);
			console.log("hallo1");
			console.log(data);
			console.log("hallo2");
			if(Object.keys(data).length>0){
				state.messages = data.msgs;
				state.messageUsers = data.msgs_users;
				state.error = data.error?.message || null;
				state.isMsgSuccess = true;
			} else {
				state.isMsgSuccess = false;
			}
		}
    });

    builder.addCase(getMessages.rejected, (state, action) => {
		console.log("yeah2");
	  state.error = action.error?.message || null;
    });
  }
});

export default MessagesSlice.reducer;