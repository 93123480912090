import axios from "axios";
import getCSRFToken from '../components/Csrf';

// default
axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// CSRF
window.csrf_token_status = "unavailable";
axios.defaults.withCredentials = true;
await getCSRFToken();

// content type
/*const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).token : null;
if (token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;*/


// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
	
	if(error.response?.statusText == "Not logged in") {
		window.location = import.meta.env.VITE_APP_BASE_URL;
	} else {
		switch (error.status) {
		  case 500:
			message = "Internal Server Error 🙈";
			break;
		  case 401:
			message = "Ungültiger CSRF-Token 😲🤨 Bitte Seite neu laden";
			break;
		  case 404:
			message = "Sorry! Die gesuchten Daten konnten nicht ermittelt werden. Klassischer 404 🙄";
			break;
		  default:
			message = error.message || error;
		}
		return Promise.reject(message);
	}
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {	
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };