import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

//Messages
import MessagesReducer from "./messages/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Messages: MessagesReducer,
});

export default rootReducer;