import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getMessages as getMessagesApi,
} from "@./helpers/frontend_helper";

export const getMessages = createAsyncThunk("messages/getMessages", async (caseExtId) => {
  try {
    const response = getMessagesApi(caseExtId);
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewCase = createAsyncThunk("cases/addNewCase", async (product) => {
  try {
    const response = addNewCaseApi(product);
    const data = await response;
    toast.success("Case Added Successfully", { autoClose: 3000 });
    return data;
  } catch (error) {
    toast.error("Case Added Failed", { autoClose: 3000 });
    return error;
  }
});